'use strict';

// Import to make Webpack include favicons for index.tpl.html
require.context('../images/favicons', true, /\.(ico|png|svg|webmanifest|xml)$/);

import '../../node_modules/angular-i18n/angular-locale_en.js';

/**
 * Main module of the application.
 *
 * @ngdoc overview
 * @name uasApp
 * @description
 * # uasApp
 */
angular.module('uasApp', [
    'ngAnimate', // angular-animate
    'ngCookies', // angular-cookies
    'ngResource', // angular-resource
    'ui.router', // @uirouter/angularjs
    'ct.ui.router.extras', // ui-router-extras
    'ngSanitize', // angular-sanitize
    'ngTouch', // angular-touch
    'dndLists', // angular-drag-and-drop-lists
    'ui.bootstrap', // angular-ui-bootstrap
    'ui.bootstrap.showErrors', // angular-bootstrap-show-errors
    'gridster', // angular-gridster
    'ncy-angular-breadcrumb', // angular-breadcrumb
    'pascalprecht.translate', // angular-translate
    'prettyXml', // angular-pretty-xml
    'ngFileUpload', // ng-file-upload
    'tmh.dynamicLocale', //  angular-dynamic-locale
    'dynamicNumber', // vendor/dynamic-number.js
    'chart.js', // angular-chart.js
    'jarb-input', // es6/app/forms/validation/jarb
    'ngclipboard', // ngclipboard
    'ngQuill', // ng-quill
    'ngMessages' // angular-messages
])
    .config(function ($compileProvider) {
        // Automatically defined by webpack
        // eslint-disable-next-line no-undef
        const APP = TE_CU_APP;
        const debugInfoEnabled = !_.isEmpty(APP) && !_.isEmpty(APP.ENV) && APP.ENV === 'development';

        $compileProvider.debugInfoEnabled(debugInfoEnabled);
    })

    .config(function ($httpProvider) {
        $httpProvider.interceptors.push('authenticationErrorInterceptor');
        $httpProvider.interceptors.push('serverErrorInterceptor');
        $httpProvider.interceptors.push('objectLinkInterceptor');
        $httpProvider.interceptors.push('maintenanceInterceptor');
        $httpProvider.interceptors.push('preferencesInterceptor');

        $httpProvider.defaults.transformResponse = function (data, headers) {
            const dateTimeFormat = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d{3})?Z)?/;

            function reviver(key, value) {
                if (angular.isString(value) && dateTimeFormat.test(value)) {
                    return new Date(value);
                }

                return value;
            }

            if (angular.isDefined(data) && angular.isString(data)) {
                let contentType = headers('Content-Type');
                if (contentType && contentType.indexOf('application/json') === 0) {
                    return JSON.parse(data, reviver);
                }
            }
            return data;
        };
    })

    .config(function ($translateProvider) {
        // Uses the languageLoader service
        $translateProvider.useLoader('languageLoader');
        $translateProvider.useMissingTranslationHandler('missingTranslationHandler');
        $translateProvider.useSanitizeValueStrategy('escape');
        $translateProvider.useLocalStorage();
    })

    .config(function (showErrorsConfigProvider) {
        // Validation settings for forms
        showErrorsConfigProvider.showSuccess(true);
        showErrorsConfigProvider.trigger('keypress');
    })

    .config(function ($urlRouterProvider, $stateProvider) {
        $urlRouterProvider.otherwise('/login');
        $stateProvider.state('dashboard', {
            url: '/dashboard',
            template: '<uas-dashboard></uas-dashboard>',
            breadcrumb: {
                label: 'Static.Page.Dashboard',
                root: true
            }
        }).state('request-log', {
            url: '/request-log',
            template: '<request-log></request-log>',
            breadcrumb: {
                label: 'Static.Page.RequestLog',
                root: true
            },
            admin: true
        }).state('settings', {
            url: '/settings',
            template: '<uas-settings></uas-settings>',
            breadcrumb: {
                label: 'Static.Label.Settings',
                root: true
            }
        }).state('labels', {
            url: '/labels',
            template: '<uas-label-list></uas-label-list>',
            breadcrumb: {
                label: 'Static.Page.Labels',
                root: true
            },
            admin: true
        }).state('elements', {
            url: '/elements',
            template: '<element-tabs></element-tabs>',
            breadcrumb: {
                label: 'Static.Page.Elements',
                root: true
            },
            admin: true
        }).state('audit-log', {
            url: '/audit-log',
            template: '<audit-log></audit-log>',
            data: {
                label: 'Static.Page.AuditLog',
                root: true
            },
            admin: true
        }).state('maintenance', {
            url: '/maintenance',
            template: '<maintenance></maintenance>',
            data: {
                public: true,
                root: true,
                background: true
            }
        });
    })

    .config(function (tmhDynamicLocaleProvider) {
        tmhDynamicLocaleProvider.localeLocationPattern('locales/angular-locale_{{locale}}.js');
    })

    .config(function ($uibModalProvider) {
        // Modal backdrop present but modal does not close when clicking outside modal
        $uibModalProvider.options.backdrop = 'static';

        // Enable customer specific styling on modals
        $uibModalProvider.options.windowClass = 'customer-body';
    })

    .run(function ($rootScope, Customer, System) {
        System.environment().$promise.then(function (environment) {
            $rootScope.environment = environment;

            Customer.get().$promise.then(function (customer) {
              $rootScope.customer = customer;
            });
        });

        System.criticalHealth().$promise.then(function (criticalHealth) {
            $rootScope.criticalHealth = criticalHealth;
        }).catch(function (response) {
            if (response.status === 503 && response.data.status === 'DOWN') {
                $rootScope.criticalHealth = { 'status': 'DOWN' };
            } else {
                $rootScope.criticalHealth = { 'status': 'UNKNOWN' };
            }
        });

        System.clientInfo().$promise.then(function (clientInfo) {
            $rootScope.clientInfo = clientInfo;
        });

        System.reportInfo().$promise.then(function (reportInfo) {
            const build = reportInfo.build;
            if (angular.isDefined(build.artifact)) {
                $rootScope.reportInfo = build.artifact + '-' + build.version;
            }
        });

        System.info().$promise.then(function (systemInfo) {
            $rootScope.systemInfo = systemInfo;
        });
    })

    .run(function($rootScope, $state, Element, CustomPage, AuthService, jarbInputStore, Reference, ReferenceType) {
        let loaded = false;

        $rootScope.$on('$stateChangeSuccess', function () {
            if (loaded === false && AuthService.isAuthenticated()) {
                loaded = true;
                loadData();
            }
        });

        function loadData() {
            Element.load();
            Reference.load();
            ReferenceType.load();

            CustomPage.query().$promise.then(function (pages) {
                const states = $state.get();
                _.each(pages, function (page) {
                    const state = _.find(states, {
                        name: page.route
                    });
                    if (angular.isDefined(state)) {
                        state.videoId = page.videoId;
                        state.visible = page.visible;
                    }
                });
                $rootScope.$broadcast('$state:secured');
            });

            jarbInputStore.loadConstraints();
        }
    })

    .run(function ($rootScope, $translate, Language, AcademicYearHolder, tmhDynamicLocale) {
        Language.onChange((event, language) => {
            $rootScope.lang = language;
            $translate.use(language);
            tmhDynamicLocale.set('en');
        });

        AcademicYearHolder.onChange(() => {
            $rootScope.isSimulation = sessionStorage.simulation;
        });
    })

    .run(function ($rootScope, $state, $stateParams, $translate, translateFilter, AuthService, AcademicYearHolder, BreadcrumbsService, Parameter, Message, Language) {
        $rootScope.loading = true;
        Parameter.load().then(() => {
            Language.init();

            const language = Language.get();
            $translate.use(language);

            $rootScope.urlTitle = Parameter.getParameterValue('url.title', 'Curriculum');
            $rootScope.configDateFormat = Parameter.getParameterValue('ui.dateformat');
            $rootScope.configDateTimeFormat = Parameter.getParameterValue('ui.datetimeformat');
        }).finally(() => {
            $rootScope.loading = false;
        });

        $rootScope.getTitle = function () {
            const breadcrumbs = BreadcrumbsService.getBreadcrumbs();
            const breadcrumb = _.last(breadcrumbs);
            if (angular.isDefined(breadcrumb)) {
                const state = _.find($state.get(), { name: breadcrumb.name });
                const title = _.result(state, 'data.title');
                const prefix = title ? translateFilter(title) : $rootScope.urlTitle;
                const label = translateFilter(breadcrumb.label || '');
                return `${prefix} - ${label}`;
            } else {
                return $rootScope.urlTitle;
            }
        };

        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;
        $rootScope.Message = Message;

        $rootScope.isAuthorized = AuthService.isAuthorized;
        $rootScope.lang = '';
        $rootScope.AcademicYearHolder = AcademicYearHolder;

    })

    .run(function ($rootScope, $timeout) {
        $rootScope.translationsLoaded = false;
        $rootScope.$on('$translateLoadingEnd', () => {
            $timeout(() => $rootScope.translationsLoaded = true, 0);
        });
    })

    .run(function ($rootScope, $templateCache) {
        // Automatically defined by webpack
        // eslint-disable-next-line no-undef
        const APP = TE_CU_APP;

        $rootScope.APP = APP;
        $rootScope.isProduction = !_.isEmpty(APP) && !_.isEmpty(APP.ENV) && APP.ENV === 'production';

        // These are the only ng-include with a relative path in the application. We can remove this when we move our header and footer to components.
        // When we move our header and footer to components, we can remove this
        $rootScope.headerTemplatePath =  $rootScope.isProduction ? 'header.html' : 'views/partials/header.html';
        $rootScope.footerTemplatePath =  $rootScope.isProduction ? 'footer.html' : 'views/partials/footer.html';

        if ($rootScope.isProduction) {
            // Load all templates into the template cache, needed for ng-include in Webpack production builds. Regex needs to be a literal.
            const modules = requireAll(require.context('../', true, /^(?!.*index\.tpl\.html$).*\.html$/));

            modules.forEach(function (val) {
                $templateCache.put(val.name, val.tpl);
            });
        }

        function requireAll(requireContext) {
            return requireContext.keys().map(function (val) {
                return {
                    tpl: requireContext(val), // Require the file, returning file contents as a string as configured using html-loader.

                    name: val.split('/').pop() // Use the file name as template name
                };
              });
        }
    });
